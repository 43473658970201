import React, { Component } from 'react';

import {
  Row,
  Col,
  Card, 
  CardBody,
  Container, 
  CardTitle, 
  CardSubtitle
} from "reactstrap";
import { Link, NavLink } from 'react-router-dom';

import logo from "./images/logo/no-pic.png";
import profileImg from "./images/logo/logo.png";

import logo_wa from "./images/logo/whatsapp.png";
import logo_instagram from "./images/logo/instagram.png";
import logo_tiktok from "./images/logo/tiktok.png";
import logo_facebook from "./images/logo/facebook.png";
import logo_website from "./images/logo/website.png";

import img_car from "./images/logo/car.png";

class App extends Component {
  render() {
    return (
      <React.Fragment>
        <Row>
          <Col>
            <Card className="mt-4 maintenance-box">
              <CardBody>
                <section className="my-5 pt-sm-5">
                  <Container>
                      <div className="bg-soft-primary">
                        <Row>
                          <Col xs="7">
                            <div className="text-primary p-3">
                              <h5 className="text-primary"></h5>
                              <p></p>
                            </div>
                          </Col>
                          <Col xs="5" className="align-self-end">
                            <img src={profileImg} alt="" className="img-fluid  p-4" />
                          </Col>
                        </Row>
                      </div>
                      <CardBody className="pt-0">
                        <Row>
                          <Col lg={3}>
                                <div className="avatar-md profile-user-wid mb-4">
                                    <img src={logo} alt="" className="img-thumbnail rounded-circle" />
                                </div>
                                <h5 className="font-size-15 text-truncate">Agus Ariawan</h5>
                                <p className="text-muted mb-0 text-truncate">Tour & Transport</p>
                            </Col>
                            <Col lg={9}>
                              <Card>
                                <CardBody>
                                  <Row>
                                    <Col>
                                  <h5 className='mb-3'>Welcome to Bali Heavenly Tour</h5>
                                  <p className='text-muted'>Enjoy your Transport and Private Tour in Bali with my family driver team.</p>
                                  <p className='text-muted'>
                                  Hi, I’m Agus! As freelance tour driver. I’m from Denpasar, BALI, and I am the founder of bali heavenly tour. I started bali heavenly tour because I have a strong passion for sharing to all people about bali travel tour activities and things to do in bali during your vacation, as well as places to visit in Bali.
                                  </p>
                                  <p className='text-muted'>
                                    Here the packages you can grab it :<br></br>
                                    - Half Day Bali Tour<br></br>
                                    - Full Day Bali Tour<br></br>
                                    - Airport Hotel Transfer<br></br>
                                    - Bali Volkswagen Private Charter<br></br>
                                    - Activities Packages<br></br>
                                    - Car Rental<br></br>
                                    - Motorbike Rental</p>
                                  <p className='text-muted'>And get more informations by direct message to my contact account bellow :</p>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col>
                                      <h5 className='mb-3'>Contact Us</h5>
                                      <div className='hstack gap-2'>
                                        <a href='https://wa.me/message/5EC7L4DJZ5D2O1' target='_blank' className='bg-light p-3 d-flex mb-3 rounded' title='whatsapp bali heavenly tour & transport'>
                                          <img className='avatar-sm rounded mr-3' src={logo_wa}></img>
                                          <div className='flex-grow-1'>
                                            <h6 className='mb-1 font-size-15'>Whatsapp</h6>
                                            <p className='text-muted mb-0'>https://wa.me/message/5EC7L4DJZ5D2O1</p>
                                          </div>
                                          <div>
                                            <button className='btn btn-soft-primary'><i className='fas fa-share-alt'></i></button>
                                          </div>
                                        </a>
                                        <a href='https://instagram.com/agus_ariawan?igshid=MzNlNGNkZWQ4Mg==' target='_blank' title='instagram bali heavenly tour & transport' className='bg-light p-3 d-flex mb-3 rounded'>
                                          <img className='avatar-sm rounded mr-3' src={logo_instagram}></img>
                                          <div className='flex-grow-1'>
                                            <h6 className='mb-1 font-size-15'>Instagram</h6>
                                            <p className='text-muted mb-0'>https://instagram.com/agus_ariawan?igshid=MzNlNGNkZWQ4Mg==</p>
                                          </div>
                                          <div>
                                            <button className='btn btn-soft-primary'><i className='fas fa-share-alt'></i></button>
                                          </div>
                                        </a>
                                        <a href='https://www.tiktok.com/@agus_ariawan16?_t=8cTBUaSyCcz&_r=1' title='tiktok bali heavenly tour & transport' target='_blank' className='bg-light p-3 d-flex mb-3 rounded'>
                                          <img className='avatar-sm rounded mr-3' src={logo_tiktok}></img>
                                          <div className='flex-grow-1'>
                                            <h6 className='mb-1 font-size-15'>Tiktok</h6>
                                            <p className='text-muted mb-0'>https://www.tiktok.com/@agus_ariawan16?_t=8cTBUaSyCcz&_r=1</p>
                                          </div>
                                          <div>
                                            <button className='btn btn-soft-primary'><i className='fas fa-share-alt'></i></button>
                                          </div>
                                        </a>
                                        <a href='https://www.facebook.com/agus.ariawanVC?mibextid=ZbWKwL' target='_blank' title='fcebook bali heavenly tour & transport' className='bg-light p-3 d-flex mb-3 rounded'>
                                          <img className='avatar-sm rounded mr-3' src={logo_facebook}></img>
                                          <div className='flex-grow-1'>
                                            <h6 className='mb-1 font-size-15'>Facebook</h6>
                                            <p className='text-muted mb-0'>https://www.facebook.com/agus.ariawanVC?mibextid=ZbWKwL</p>
                                          </div>
                                          <div>
                                            <button className='btn btn-soft-primary'><i className='fas fa-share-alt'></i></button>
                                          </div>
                                        </a>
                                        <a href='https://www.toyabalitour.com/car-rental-with-driver/' target='_blank' title='website bali heavenly tour & transport' className='bg-light p-3 d-flex mb-3 rounded'>
                                          <img className='avatar-sm rounded mr-3' src={logo_website}></img>
                                          <div className='flex-grow-1'>
                                            <h6 className='mb-1 font-size-15'>Website</h6>
                                            <p className='text-muted mb-0'>https://www.toyabalitour.com/car-rental-with-driver/</p>
                                          </div>
                                          <div>
                                            <button className='btn btn-soft-primary'><i className='fas fa-share-alt'></i></button>
                                          </div>
                                        </a>
                                      </div>
                                    </Col>
                                  </Row>
                                </CardBody>
                              </Card>
                              
                            </Col>
                        </Row>
                      </CardBody>
                        
                  </Container>
                </section>
              </CardBody>
            </Card>
          </Col>
        </Row>
        
        <div id="animate"  >
          <img src={img_car} className="img-fluid" width="100px" height="100px" />
        </div>

        <footer className="footer">
	                <Container fluid={false}>
	                    <Row>
	                        <Col md={12} style={{textAlign:'center'}}>
	                            {new Date().getFullYear()} © Bali Heavenly Tour & Transport.
	                        </Col>
	                    </Row>
	                </Container>
    </footer>
      </React.Fragment>
    );
  }
}

export default App;